import { Flex } from '@chakra-ui/react';
import React from 'react';

const Loader = () => {
    return (

       <div class="center">
        <div class="body"></div>
  <div class="loader">
    <div class="dot"> </div>
    <div class="dot"></div>
  </div>
  <div class="text">Connecting</div>
</div>
      
    );
};

export default Loader;