import { Flex, Heading, Image, Button, Text, Menu,
  MenuButton,
  MenuList, IconButton, Box } from '@chakra-ui/react';
import { FaBars } from 'react-icons/fa';
import React, { useEffect } from 'react';
import Logo from '../Images/logo.png';
import { Link } from 'react-router-dom';

import { ConnectButton } from '@rainbow-me/rainbowkit';

const Navbar = () => {

    return (
        <Flex justify="space-between" align="center" mb="30px" py="25px" position="contain" top="0" zIndex="contain" >
       

        <Flex gap="20px" align="center" wrap="wrap" display={{base: 'none', lg: 'flex'}}>

              <Link to="/">
                <Text fontSize="25px" color="blue.300" _hover={{color: 'primary.500'}}>
                  Terminal
                </Text>
              </Link>

              <Link to="/Leaderboard">
                <Text fontSize="25px" color="blue.300" _hover={{color: 'primary.500'}}>
                  Leaderboard
                </Text>
              </Link>

              <Link to="/Mint">
                <Text fontSize="25px" color="blue.300" _hover={{color: 'primary.500'}}>
                  Mint
                </Text>
              </Link>

              <Link to="/Faq">
                <Text fontSize="25px" color="blue.300" _hover={{color: 'primary.500'}}>
                 Faq
                </Text>
              </Link>

              <Link to="/Dao">
                <Text fontSize="25px" color="blue.300" _hover={{color: 'primary.500'}}>
                 DAO
                </Text>
              </Link>

              
        </Flex>

      <Box display={{base: 'none', lg: 'block'}}> 
              <ConnectButton />
      </Box>

        <Box display={{base: 'block', lg: 'none'}}>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label='Options'
              icon={<FaBars />}
              colorScheme="black"
              font-family='hacked-kerX'
            />
            <MenuList bg="transparent" >

                <ConnectButton />
                <Link to='/Mint'>
                  <Text fontSize="25px" color="white" _hover={{bg: 'primary.500'}} px="15px">
                    Mint
                  </Text>
                </Link>

                <Link to="/Leaderboard">
                <Text fontSize="25px" color="white" _hover={{bg: 'primary.500'}} px="15px">
                  Leaderboard
                </Text>
              </Link>

                <Link to="/">
                  <Text fontSize="25px" color="white" _hover={{bg: 'primary.500'}} px="15px">
                    Terminal
                  </Text>
                </Link>

                <Link to="/Faq">
                <Text fontSize="25px" color="white" _hover={{bg: 'primary.500'}} px="15px">
                  Faq
                </Text>
              </Link>


              <Link to="/Dao">
                <Text fontSize="25px" color="blue.300" _hover={{bg: 'primary.500'}}>
                 DAO
                </Text>
              </Link>


                

                
            </MenuList>
          </Menu>
        </Box>
    </Flex>
    );
};

export default Navbar;