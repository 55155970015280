import { Box, Button, Card, CardBody, CardFooter, CardHeader, Flex, Grid, Heading, HStack, Image, Input, Link, Progress, Stack, Text, useNumberInput } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import img from '../Images/56.png';

import { useAccount } from 'wagmi';

import { ethers } from 'ethers';
import AlphaLions from '../artifacts/contracts/LionNFT.sol/LionNFT.json';
import Swal from 'sweetalert2';
const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const ALaddress = '0x6aE30c8b30dE30E6207076264931F543821BB520';



const Mint = () => {

    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
        step: 1,
        defaultValue: 1,
        min: 1,
        max: 2,
        precision: 0,
    })
  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()
  const value = input.value;
//   console.log(value);

  const { address, isConnecting, isDisconnected} = useAccount();

  const [data, setData] = useState({});
  const [owner, setOwner] = useState(false);

  useEffect(() => {
    getContractOwner();
    fetchData();
  }, [address])

  async function getContractOwner() {
    if(typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ALaddress, AlphaLions.abi, provider);
      try {
       if(address) {
          const owner = await contract.owner();
          if(owner.toLowerCase() === address.toLowerCase()) {
            setOwner(true);
          } else {
            setOwner(false);
          }
       }
      }
      catch (err) {
        console.log(err.message);
      }
    }
  }

  async function fetchData() {
    if(typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ALaddress, AlphaLions.abi, provider);
      try {
        const mintPrice = await contract.cost();
        const totalSupply = await contract.totalSupply();
        const object = {"price": String(mintPrice), "totalSupply": String(totalSupply)};
        setData(object);
      }
      catch (err) {
        console.log(err.message);
      }
    }
  }
  

  async function mint() {
    if(typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(ALaddress, AlphaLions.abi, signer);
      try {
        let overrides = {
          from: address,
          value: (data.price * value).toString()
        }
        const transaction = await contract.mint(value, overrides);
        await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
        Toast.fire({icon: 'success',title: 'Minted Please View In Terminal.'})
        fetchData();
      }
      catch (err) {
        Toast.fire({icon: 'error',title: err.message})
        console.log(err.message);
        console.log(err.message);
      }
    }
  }

  async function withdraw() {
    if(typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(ALaddress, AlphaLions.abi, signer);
      try {
        const transaction = await contract.withdrawETH();
        await transaction.wait();
      }
      catch (err) {
        console.log(err.message);
      }
    }
  }


    return (
        <Box  mb="100px">

            <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap="60px">
                <Stack order={{base: '2', md:'1'}} spacing="25px" >
                    <Text color="white" fontSize="5xl" fontWeight="bold">
                        ALLBOTS
                    </Text>
                    <br></br>
          
      
           
                    <Flex justify="space-between" align="center">
                        <Text className='nftMint' fontSize="18px" color="primary.600">
                            TOTAL BOTS: <span style={{fontWeight: 'bold', color: 'white'}}>100</span>
                        </Text>
                        <br></br>

                        <Text className='nftMint' fontSize="18px" color="primary.600">
                            PRICE: <span style={{fontWeight: 'bold', color: 'white'}}>0.5 ETH</span>
                        </Text>

                    </Flex>

                    <Flex justify="space-between" align="center" wrap='wrap' gap="15px">
                    <Text className='nftMint'>
                        Network: <span>Ethereum</span>
                    </Text>


                    </Flex>

                    <Text className='nftMint1'>
                    The ALLBOTS were the first humanoid guardians designed to support the AllIn Society,
                    they were programmed to protect… Now they battle for XP, Allinium and supremacy.
                    Mint your ALLBOT Today!
                    </Text>
                    

                    <Link href='https://opensea.io/collection/allbots-1' _hover={{textDecoration: 'none'}} target="_blank">
                        <Button border="1px"  w="100%">
                            View on Opensea
                        </Button>
                    </Link>

                    { owner && (
                        <>
                            <Button border="1px" onClick={withdraw}>withdraw</Button>
                        </>
                    )
                    }

                </Stack>

                <Stack order={{base: '1', md:'1'}} spacing="25px">
                    <Flex justify="center">
                      <Image src={img} alt="nftImage"borderRadius="30px" w={{base: 'auto', lg: "300px"}} h="300px" objectFit="contain"/>
                    </Flex>

                    <Box>
                        <Flex justify="space-between" fontSize="17px">
                            <span className='nftMint'>Total minted</span>
                            <Text className='nftMint'><span style={{color: 'white', fontWeight: 'bold'}}>{((data.totalSupply/100)*100).toFixed(0)}%</span> ({data.totalSupply}/100)</Text>
                        </Flex>
                        <Box mt="5px">
                            <Progress bg="secondary.800" size="md" value={((data.totalSupply/333)*100).toFixed(0)} colorScheme="green" borderRadius="full"/>
                        </Box>
                    </Box>

                    <Card bg="secondary.700" color="white">
                        <CardHeader fontSize="18px" display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap="15px">
                        <Text className='nftMint' >
                            Price: <span style={{fontWeight: 'bold'}}>0.5 ETH</span>
                        </Text>
                        <HStack maxW='320px'>
                            <Button {...dec} colorScheme="cyan">-</Button>
                            <Input {...input}/>
                            <Button {...inc} colorScheme="cyan">+</Button>
                        </HStack>
                        </CardHeader>

                        <CardBody>
                            <Button className='buttontxt' border="1px" w="100%" colorScheme="white" onClick={mint} disabled={isDisconnected && true}>Mint {value}</Button>
                        </CardBody>
                    </Card>
                </Stack>
            </Grid>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

        </Box>
    );
};

export default Mint;